import { useTranslation } from "react-i18next";
import { discovery } from "../assets";
import "./Tours.css";

const Tours = () => {
  const { t } = useTranslation();
  return (
    <div className="posts-container">
      <img src={discovery} className="post-img" alt="discovery" />
      <section className="post">
        <h3 className="post-title">{t("tours.discoveryTitle")}</h3>
        <p>{t("tours.duration")}: 60min</p>
        <p>{t("tours.discoveryDescription")}</p>
        <table>
          <tbody>
            <tr>
              <th colSpan="2">{t("tours.priceList")}</th>
            </tr>
            <tr>
              <td>$299</td>
              <td>Max 3 People</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default Tours;
