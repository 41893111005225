import { Link } from "react-router-dom";
import { video } from "../assets";
import "./Home.css";

const Home = () => {
  return (
    <>
      <video playsInline autoPlay muted loop>
        <source src={video} type="video/mp4" />
      </video>
      <div className="name">
        <span>Baymax</span>
        <span>Aviation</span>
      </div>
      <div className="enter-container">
        <Link to="/tours" className="enter-link">
          Enter
        </Link>
      </div>
    </>
  );
};

export default Home;
