import { faq1, faq2 } from "../assets";
import "./FAQ.css";

const FAQ = () => {
  return (
    <div className="faq-container">
      <img src={faq1} className="faq-img" alt="faq1" />
      <img src={faq2} className="faq-img" alt="faq2" />
    </div>
  );
};

export default FAQ;
