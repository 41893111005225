import { useTranslation } from "react-i18next";
import { privatePilot, instrument, commercial } from "../assets";

const Training = () => {
  const { t } = useTranslation();

  return (
    <div className="posts-container">
      <img src={privatePilot} className="post-img" alt="privatePilot" />
      <section className="post">
        <h2 className="post-title">{t("private.title")}</h2>
        <p>{t("private.p1")}</p>
        <p>{t("private.p2")}</p>
        <h3 style={{ fontWeight: "normal" }}>{t("private.requirement")}</h3>
        <ul>
          <li>{t("private.1")}</li>
          <li>
            {t("private.2")}
            <ul>
              <li>{t("private.2_1")}</li>
              <li>{t("private.2_2")}</li>
              <li>
                {t("private.2_3")}
                <ul>
                  <li>{t("private.2_3_1")}</li>
                  <li>{t("private.2_3_2")}</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            {t("private.3")}
            <ul>
              <li>
                {t("private.3_1")}
                <ul>
                  <li>{t("private.3_1_1")}</li>
                  <li>{t("private.3_1_2")}</li>
                  <li>{t("private.3_1_3")}</li>
                </ul>
              </li>
              <li>{t("private.3_2")}</li>
            </ul>
          </li>
        </ul>
      </section>
      <img src={instrument} className="post-img" alt="instrument" />
      <section className="post">
        <h2 className="post-title">{t("instrument.title")}</h2>
        <p>{t("instrument.p")}</p>
        <h3 style={{ fontWeight: "normal" }}>{t("instrument.requirement")}</h3>
        <ul>
          <li>{t("instrument.1")}</li>
          <li>
            {t("instrument.2")}
            <ul>
              <li>
                {t("instrument.2_1")}
                <ul>
                  <li>{t("instrument.2_1_1")}</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </section>
      <img src={commercial} className="post-img" alt="commercial" />
      <section className="post">
        <h2 className="post-title">{t("commercial.title")}</h2>
        <p>{t("commercial.p")}</p>
        <h3 style={{ fontWeight: "normal" }}>{t("commercial.requirement")}</h3>
        <ul>
          <li>{t("commercial.1")}</li>
          <li>{t("commercial.2")}</li>
          <li>
            {t("commercial.3")}
            <ul>
              <li>{t("commercial.3_1")}</li>
              <li>{t("commercial.3_2")}</li>
              <li>{t("commercial.3_3")}</li>
              <li>{t("commercial.3_4")}</li>
            </ul>
          </li>
          <li>
            {t("commercial.4")}
            <ul>
              <li>{t("commercial.4_1")}</li>
              <li>{t("commercial.4_2")}</li>
            </ul>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Training;
