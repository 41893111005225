export const video = require("./video.mp4");
export const background = require("./background.webp");

export const discovery = require("./discovery.webp");
export const privatePilot = require("./privatePilot.webp");
export const instrument = require("./instrument.webp");
export const commercial = require("./commercial.webp");

export const faq1 = require("./faq1.webp");
export const faq2 = require("./faq2.webp");

export const phone = require("./phone.webp");
export const email = require("./email.webp");
export const address = require("./address.webp");
export const wechatIcon = require("./wechat-icon.webp");
export const wechat = require("./wechat.webp");
