import { useAuth0 } from "@auth0/auth0-react";
import "./Profile.css";

const Profile = () => {
  const { logout, user, isAuthenticated } = useAuth0();
  return (
    isAuthenticated && (
      <>
        <button
          onClick={() =>
            logout({
              logoutParams: { returnTo: "https://baymaxaviation.com/contact" },
            })
          }
        >
          Log Out
        </button>
        <iframe
          title="profile"
          src={
            "https://docs.google.com/spreadsheets/d/e/" +
            user.name +
            "/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"
          }
        ></iframe>
      </>
    )
  );
};

export default Profile;
