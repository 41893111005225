import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "./i18n";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Auth0Provider
      domain="dev-4fa6hcdfgoomjg7m.us.auth0.com"
      clientId="LKOjlPwVG1S7f6Zn8fio1fzXu38OkhAs"
      authorizationParams={{
        redirect_uri: "https://baymaxaviation.com/profile",
      }}
    >
      <App />
    </Auth0Provider>
  </BrowserRouter>
);
